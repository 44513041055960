import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { sv as locale } from '@norban/locale';
import React, { useMemo } from 'react';

import DateSelector from '../components/DateSelector';
import { isValidDate } from '../utils';

export default function useFilterRowCreatedDateRange(
  createdDateEnabled: boolean,
  createdDateRange: [string | undefined, string | undefined],
  onEnabledChanged: (value: boolean) => void,
  onValueChanged: (dateRange: [string | undefined, string | undefined]) => void,
) {
  const L = locale.backoffice;

  return useMemo(
    () => ({
      label: L.allHomes.created,
      icon: <CalendarTodayIcon />,
      enabled: createdDateEnabled,
      onEnabledChanged,
      children: (
        <DateSelector
          disabled={!createdDateEnabled}
          startDate={createdDateRange[0] ? new Date(createdDateRange[0]) : ''}
          endDate={createdDateRange[1] ? new Date(createdDateRange[1]) : ''}
          startLabel={L.from}
          endLabel={L.to}
          onChangeStartDate={(date: Date | null) =>
            onValueChanged([
              date && isValidDate(date) ? date.toISOString() : undefined,
              createdDateRange[1],
            ])
          }
          onChangeEndDate={(date: Date | null) =>
            onValueChanged([
              createdDateRange[0],
              date && isValidDate(date) ? date.toISOString() : undefined,
            ])
          }
        />
      ),
    }),
    [
      L.allHomes.created,
      L.from,
      L.to,
      createdDateEnabled,
      createdDateRange,
      onEnabledChanged,
      onValueChanged,
    ],
  );
}
