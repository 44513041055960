import ClearIcon from '@mui/icons-material/Clear';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { Box } from '@mui/system';
import { sv as locale } from '@norban/locale';
import React from 'react';

import UserServerSideAutocomplete from '../../../../components/UserServerSideAutocomplete';
import { BofHomeProfileHomeFragment } from '../../../../generated/backend/graphql';
import { DispatchType } from '../../hooks/useHomeProfileReducer';

const L = locale.backoffice.homeProfileOwnerCard;

type Props = {
  home: BofHomeProfileHomeFragment;
  dispatch: DispatchType;
};

const OwnerCard = ({ home, dispatch }: Props) => {
  const [allowEdit, setAllowEdit] = React.useState(false);

  return (
    <Card>
      <CardHeader title={L.title} />
      <CardContent>
        <Box mb={2}>
          <FormGroup>
            <FormControlLabel
              label={L.allowEdit}
              control={
                <Checkbox
                  checked={allowEdit}
                  onChange={() => setAllowEdit(!allowEdit)}
                />
              }
            />
          </FormGroup>
        </Box>
        <Button
          disabled={!allowEdit || !home?.userId}
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => dispatch({ key: 'userId', value: null })}
          startIcon={<ClearIcon />}
        >
          Töm fältet
        </Button>
        <Box mt={4}>
          <UserServerSideAutocomplete
            disabled={!allowEdit}
            selectedUserId={home?.userId ?? null}
            onSelectedUserIdChanged={userId => {
              dispatch({ key: 'userId', value: userId });
            }}
            sortAlphabetically
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default OwnerCard;
