import StraightenIcon from '@mui/icons-material/Straighten';
import { sv as locale } from '@norban/locale';
import React, { useMemo } from 'react';

import RangeMinMaxInputs from '../components/RangeMinMaxInputs';

export default function useFilterRowLivingArea(
  livingAreaRangeEnabled: boolean,
  livingAreaRange: [number | undefined, number | undefined],
  onEnabledChanged: (value: boolean) => void,
  onValueChanged: (
    livingAreaRange: [number | undefined, number | undefined],
  ) => void,
) {
  const L = locale.backoffice;

  return useMemo(
    () => ({
      label: L.searchFollowers.homeState,
      icon: <StraightenIcon />,
      enabled: livingAreaRangeEnabled,
      onEnabledChanged,
      children: (
        <RangeMinMaxInputs
          disabled={!livingAreaRangeEnabled}
          valueMin={livingAreaRange?.[0]}
          valueMax={livingAreaRange?.[1]}
          onChange={onValueChanged}
          unit="m²"
        />
      ),
    }),
    [
      L.searchFollowers.homeState,
      onEnabledChanged,
      onValueChanged,
      livingAreaRange,
      livingAreaRangeEnabled,
    ],
  );
}
