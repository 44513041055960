import MapIcon from '@mui/icons-material/Map';
import { Box } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useMemo } from 'react';

import MultiAreaSelect from '../components/MultiAreaSelect';
import { BofAreasQuery } from '../generated/backend/graphql';

export default function useFilterRowArea(
  areasEnabled: boolean,
  areaIds: string[] | undefined,
  rootAreas: BofAreasQuery['rootAreas'] | undefined,
  onEnabledChanged: (value: boolean) => void,
  onValueChanged: (areas: string[]) => void,
) {
  const L = locale.backoffice;

  return useMemo(
    () => ({
      label: 'Bostad',
      icon: <MapIcon />,
      enabled: areasEnabled,
      onEnabledChanged,
      children: (
        <Box my={1} flex="1">
          <MultiAreaSelect
            label={L.searchFollowers.areas}
            enabled={areasEnabled}
            areas={rootAreas ?? []}
            value={areaIds ?? []}
            onValueChanged={onValueChanged}
          />
        </Box>
      ),
    }),
    [
      L.searchFollowers.areas,
      areaIds,
      areasEnabled,
      onEnabledChanged,
      onValueChanged,
      rootAreas,
    ],
  );
}
