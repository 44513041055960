import { Stack, Tab, Tabs } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import HomeFollowers from '../../../HomeFollowers';
import HomeGreetings from '../../../User/components/HomeGreetings';
import HomeInvite from '../../../User/components/HomeInvite';

import HomeMatching from './HomeMatching';

type Props = {
  homeId: string;
};

const L = locale.backoffice.homePage;

export default function HomeProspects({ homeId }: Props) {
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <Stack spacing={2}>
      <Tabs value={pathname.split('/')[4]}>
        <Tab
          component={Link}
          label={L.tabs.prospects}
          to={`${url}/followings`}
          value="followings"
        />
        <Tab
          component={Link}
          label={L.tabs.homeMessages}
          to={`${url}/greetings`}
          value="greetings"
        />
        <Tab
          component={Link}
          label={L.tabs.matching}
          to={`${url}/matching`}
          value="matching"
        />
        <Tab
          component={Link}
          label={L.tabs.inviteRecommend}
          to={`${url}/invite`}
          value="invite"
        />
      </Tabs>
      <Switch>
        <Route path={`${path}/followings`} exact>
          <HomeFollowers homeId={homeId} />
        </Route>
        <Route path={`${path}/greetings`} exact>
          <HomeGreetings homeId={homeId} />
        </Route>
        <Route path={`${path}/matching`} exact>
          <HomeMatching homeId={homeId} />
        </Route>
        <Route path={`${path}/invite`} exact>
          <HomeInvite homeId={homeId} />
        </Route>
        <Route>
          <Redirect to={`${url}/followings`} />
        </Route>
      </Switch>
    </Stack>
  );
}
