import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Stack,
  Alert,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';

interface Props {
  open: boolean;
  template: string;
  subject: string;
  onClose: () => void;
  onSave: (template: string, subject: string) => void;
}

const LeadTemplatesCustomEditor: React.FC<Props> = ({
  open,
  template,
  subject,
  onClose,
  onSave,
}) => {
  const L = locale.backoffice;
  // Read value from local storage
  const [editorText, setEditorText] = useState(template);
  const [editorSubject, setEditorSubject] = useState(subject);
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Customize Lead Template</DialogTitle>
      <DialogContent>
        {/* Legend */}
        <Stack gap={2}>
          <Typography>{L.leadTemplates.customTemplateDescription}</Typography>
          <Alert severity="info">
            {`{firstName}, {streetName}, {areaName}, {numberOfRooms}, {homeType}, {agentName}, {followerCount}, {homeMessageCount} {similarHomesFollowerCountInArea} {similarHomesFollowerCountInAssociatedAreas} {sharePMInArea}
 {shareOMInArea} {avgSqmPricePM} {avgSqmPriceOM}`}
          </Alert>
          <TextField
            multiline
            variant="outlined"
            fullWidth
            placeholder="Enter your custom lead template subject here..."
            value={editorSubject}
            onChange={e => setEditorSubject(e.target.value)}
          />
          <TextField
            multiline
            rows={10}
            variant="outlined"
            fullWidth
            placeholder="Enter your custom lead template here..."
            value={editorText}
            onChange={e => setEditorText(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onSave(editorText, editorSubject);
            onClose();
          }}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LeadTemplatesCustomEditor;
