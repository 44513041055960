import { useQuery } from '@apollo/client';
import DrawIcon from '@mui/icons-material/Draw';
import HomeIcon from '@mui/icons-material/Home';
import KeyIcon from '@mui/icons-material/Key';
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import WorkIcon from '@mui/icons-material/Work';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import { Box, Button, Grid, Table } from '@mui/material';
import { DataGrid, GridColDef, GridSortItem } from '@mui/x-data-grid';
import { svSE } from '@mui/x-data-grid/locales';
import { sv as locale } from '@norban/locale';
import { formatDate } from '@norban/utils/dist';
import { endOfDay } from 'date-fns';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { updateFilter } from '../../actions/homeActions';
import DateSelector from '../../components/DateSelector';
import FilterSection from '../../components/FilterSection';
import PageHeader from '../../components/PageHeader';
import QueryError from '../../components/QueryError';
import RootCard from '../../components/RootCard';
import {
  BofAllHomesDocument,
  BofAllHomesCountDocument,
  HomeState,
  OrderDirectionInput,
  SalesProcessStatus,
} from '../../generated/backend/graphql';
import useFilterRowCreatedDateRange from '../../hooks/useFilterRowCreatedDateRange';
import { useGlobalStateWithDelayedCallback } from '../../hooks/useGlobalStateWithDelayedCallback';
import { HomeFilter } from '../../reducers/homeFilter';
import { isValidDate } from '../../utils';
import {
  translateSalesProcessStatus,
  translateStateShort,
  translateTenure,
} from '../../utils/translateEnum';
import BoolArrayCheckboxes from '../UserList/components/BoolArrayCheckboxes';
import { FilterAdminSelect } from '../UserList/components/FilterAdminSelect';
import FilterCheckbox from '../UserList/components/FilterCheckbox';

const PAGE_SIZE = 100;

const mapSorting = (field: string): string => {
  const sortMap: { [key: string]: string } = {
    area: 'areaId',
    address: 'streetAddress',
    assignedAgent: 'assignedAgentId',
  };

  if (!sortMap[field]) {
    return field;
  }

  return sortMap[field];
};

const Homes = () => {
  const L = locale.backoffice;

  const [paginationModel, setPaginationModel] = useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });
  const [sorting, setSorting] = useState<GridSortItem>();

  const dispatch = useDispatch<Dispatch<{ type: string }>>();
  const filter = useSelector<{ homeFilter: HomeFilter }, HomeFilter>(
    state => state.homeFilter,
  );

  const [localFilter, setLocalFilter, setLocalFilterDirectly] =
    useGlobalStateWithDelayedCallback(filter, dispatch, updateFilter, 300);

  const filterAgent = () => {
    if (!filter.hasAssignedAgentEnabled) {
      return undefined;
    }

    if (filter.hasAssignedAgentInverted) {
      return null;
    }

    // Convert assignedAgentId null to undefined to get an unfiltered result
    return filter.assignedAgentId ? filter.assignedAgentId : undefined;
  };
  const filterHomeStates = () => {
    if (!filter.homeStatesEnabled) {
      return undefined;
    }

    return Object.values(HomeState).filter(
      (_, index) => filter.homeStates[index],
    );
  };
  const filterSalesProcessStatus = () => {
    if (!filter.salesProcessStatusEnabled) {
      return undefined;
    }

    return Object.values(SalesProcessStatus).filter(
      (_, index) => filter.salesProcessStatus[index],
    );
  };

  // Base filter for count and homes queries. Omit pagination to avoid unnecessary data fetching.
  const requestFilterCount = {
    order: sorting
      ? {
          field: mapSorting(sorting.field),
          direction:
            sorting.sort === 'asc'
              ? OrderDirectionInput.Asc
              : OrderDirectionInput.Desc,
        }
      : undefined,
    dateRange: filter.createdDateEnabled
      ? {
          from: filter.createdDateFrom,
          to: filter.createdDateTo,
        }
      : undefined,
    agreementDateRange: filter.agreementDateEnabled
      ? {
          from: filter.agreementDateFrom,
          to: filter.agreementDateTo,
        }
      : undefined,
    contractDateRange: filter.contractDateEnabled
      ? {
          from: filter.contractDateFrom,
          to: filter.contractDateTo,
        }
      : undefined,
    withdrawalDateRange: filter.withdrawalDateEnabled
      ? {
          from: filter.withdrawalDateFrom,
          to: filter.withdrawalDateTo,
        }
      : undefined,
    assignedAgentId: filterAgent(),
    homeStates: filterHomeStates(),
    salesProcessStatuses: filterSalesProcessStatus(),
  };
  // Add pagination to homes query
  const requestFilterHomes = {
    ...requestFilterCount,
    limit: PAGE_SIZE,
    offset: paginationModel.page * PAGE_SIZE,
  };

  const {
    loading: loadingCount,
    data: dataCount,
    error: errorCount,
  } = useQuery(BofAllHomesCountDocument, {
    variables: { filter: requestFilterCount },
  });
  const {
    loading: loadingHomes,
    data: dataHomes,
    error: errorHomes,
  } = useQuery(BofAllHomesDocument, {
    variables: { filter: requestFilterHomes },
    skip: !dataCount,
  });

  const totalCount = dataCount?.homesCount ?? 0;
  const rows = dataHomes?.homes ?? [];
  const loading = loadingCount || loadingHomes;

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: L.allHomes.homeId,
      renderHeader: () => <HomeIcon />,
      renderCell: ({ value }) => (
        <a href={`/homes/${value}`} target="_blank" rel="noreferrer">
          {value}
        </a>
      ),
    },
    {
      field: 'userId',
      headerName: L.allHomes.userId,
      renderHeader: () => <PersonIcon />,
      renderCell: ({ value }) => (
        <a href={`/users/${value}`} target="_blank" rel="noreferrer">
          {value}
        </a>
      ),
    },
    {
      field: 'address',
      headerName: L.allHomes.address,
      width: 200,
      valueGetter: ({ value: { city, streetAddress, zipCode } }) =>
        `${streetAddress ?? '-'}\n${zipCode ?? '-'} ${city ?? '-'}`,
      renderCell: ({ value }) => (
        <span style={{ whiteSpace: 'pre-wrap' }}>{value}</span>
      ),
    },
    {
      field: 'area',
      headerName: L.allHomes.area,
      width: 200,
      valueGetter: ({ value }) =>
        value?.name
          ? `${value.name}${value?.public ? '' : ' - stängt område'}`
          : '-',
    },
    {
      field: 'tenure',
      headerName: L.allHomes.tenure,
      width: 140,
      valueGetter: ({ value }) => translateTenure(value),
    },
    {
      field: 'state',
      headerName: L.allHomes.state,
      width: 120,
      valueGetter: ({ value }) => translateStateShort(value),
    },
    {
      field: 'salesProcessStatus',
      headerName: L.allHomes.salesProcessStatus,
      width: 180,
      valueGetter: ({ value }) => translateSalesProcessStatus(value),
    },
    {
      field: 'assignedAgent',
      headerName: L.allHomes.assignedAgent,
      width: 200,
      valueGetter: ({ value }) => value?.name ?? '-',
    },
    {
      field: 'created',
      headerName: L.created,
      width: 140,
      valueGetter: ({ value }) => value,
      renderCell: ({ value }) =>
        value
          ? new Intl.DateTimeFormat('sv-SE', {
              year: 'numeric',
              day: 'numeric',
              month: 'short',
            }).format(new Date(value))
          : '-',
    },
    {
      field: 'agreementDate',
      headerName: L.allHomes.dateOfAgreement,
      width: 140,
      valueGetter: ({ value }) => value,
      renderCell: ({ value }) => formatDate(value),
    },
    {
      field: 'contractDate',
      headerName: L.allHomes.dateOfContract,
      width: 140,
      valueGetter: ({ value }) => value,
      renderCell: ({ value }) => formatDate(value),
    },
    {
      field: 'withdrawalDate',
      headerName: L.allHomes.dateOfWithdrawal,
      width: 140,
      valueGetter: ({ value }) => value,
      renderCell: ({ value }) => formatDate(value),
    },
  ];

  const handleClear = () => {
    const defaultState: HomeFilter = {
      ...filter,
      createdDateEnabled: false,
      createdDateFrom: undefined,
      createdDateTo: undefined,
      agreementDateEnabled: false,
      agreementDateFrom: undefined,
      agreementDateTo: undefined,
      contractDateEnabled: false,
      contractDateFrom: undefined,
      contractDateTo: undefined,
      withdrawalDateEnabled: false,
      withdrawalDateFrom: undefined,
      withdrawalDateTo: undefined,
      hasAssignedAgentEnabled: false,
      assignedAgentId: null,
      homeStatesEnabled: false,
      homeStates: Object.keys(HomeState).map(() => true),
      salesProcessStatusEnabled: false,
      salesProcessStatus: Object.keys(SalesProcessStatus).map(() => true),
    };
    setLocalFilterDirectly({
      ...defaultState,
    });
  };

  const dateRangeFilter = useFilterRowCreatedDateRange(
    filter.createdDateEnabled,
    [filter.createdDateFrom, filter.createdDateTo],
    value => {
      updateFilter(dispatch, {
        ...filter,
        createdDateEnabled: value,
      });
    },
    value => {
      setLocalFilter({
        ...filter,
        createdDateFrom: value[0],
        createdDateTo: value[1],
      });
    },
  );

  if (errorCount || errorHomes) {
    return (
      <QueryError
        error={errorCount ?? errorHomes}
        data={dataCount ?? dataHomes}
      />
    );
  }

  return (
    <>
      <PageHeader title={L.allHomes.title} />
      <RootCard>
        <Table size="small">
          <FilterSection
            title={L.allHomes.filter}
            expanded={filter.dateSection}
            onExpandedChanged={value =>
              updateFilter(dispatch, {
                ...filter,
                dateSection: value,
              })
            }
            filters={[
              dateRangeFilter,
              {
                label: L.allHomes.agreementContractSigned,
                icon: <DrawIcon />,
                enabled: filter.agreementDateEnabled,
                onEnabledChanged: value => {
                  updateFilter(dispatch, {
                    ...filter,
                    agreementDateEnabled: value,
                  });
                },
                children: (
                  <DateSelector
                    disabled={!filter.agreementDateEnabled}
                    startDate={localFilter?.agreementDateFrom ?? ''}
                    endDate={localFilter?.agreementDateTo ?? ''}
                    startLabel={L.from}
                    endLabel={L.to}
                    onChangeStartDate={(date: Date | null) =>
                      setLocalFilter({
                        ...filter,
                        agreementDateFrom:
                          date && isValidDate(date)
                            ? date.toISOString()
                            : undefined,
                      })
                    }
                    onChangeEndDate={(date: Date | null) =>
                      setLocalFilter({
                        ...filter,
                        agreementDateTo:
                          date && isValidDate(date)
                            ? endOfDay(date).toISOString()
                            : undefined,
                      })
                    }
                  />
                ),
              },
              {
                label: L.allHomes.contractSigned,
                icon: <KeyIcon />,
                enabled: filter.contractDateEnabled,
                onEnabledChanged: value => {
                  updateFilter(dispatch, {
                    ...filter,
                    contractDateEnabled: value,
                  });
                },
                children: (
                  <DateSelector
                    disabled={!filter.contractDateEnabled}
                    startDate={localFilter?.contractDateFrom ?? ''}
                    endDate={localFilter?.contractDateTo ?? ''}
                    startLabel={L.from}
                    endLabel={L.to}
                    onChangeStartDate={(date: Date | null) =>
                      setLocalFilter({
                        ...filter,
                        contractDateFrom:
                          date && isValidDate(date)
                            ? date.toISOString()
                            : undefined,
                      })
                    }
                    onChangeEndDate={(date: Date | null) =>
                      setLocalFilter({
                        ...filter,
                        contractDateTo:
                          date && isValidDate(date)
                            ? endOfDay(date).toISOString()
                            : undefined,
                      })
                    }
                  />
                ),
              },
              {
                label: L.allHomes.withdrawal,
                icon: <WrongLocationIcon />,
                enabled: filter.withdrawalDateEnabled,
                onEnabledChanged: value => {
                  updateFilter(dispatch, {
                    ...filter,
                    withdrawalDateEnabled: value,
                  });
                },
                children: (
                  <DateSelector
                    disabled={!filter.withdrawalDateEnabled}
                    startDate={localFilter?.withdrawalDateFrom ?? ''}
                    endDate={localFilter?.withdrawalDateTo ?? ''}
                    startLabel={L.from}
                    endLabel={L.to}
                    onChangeStartDate={(date: Date | null) =>
                      setLocalFilter({
                        ...filter,
                        withdrawalDateFrom:
                          date && isValidDate(date)
                            ? date.toISOString()
                            : undefined,
                      })
                    }
                    onChangeEndDate={(date: Date | null) =>
                      setLocalFilter({
                        ...filter,
                        withdrawalDateTo:
                          date && isValidDate(date)
                            ? endOfDay(date).toISOString()
                            : undefined,
                      })
                    }
                  />
                ),
              },
              {
                label: L.allHomes.assignedAgent,
                icon: <SupportAgentIcon />,
                enabled: filter.hasAssignedAgentEnabled,
                onEnabledChanged: value => {
                  updateFilter(dispatch, {
                    ...filter,
                    hasAssignedAgentEnabled: value,
                  });
                },
                children: (
                  <Grid item xs={12} alignItems="center" display="flex">
                    <FilterCheckbox
                      disabled={!filter.hasAssignedAgentEnabled}
                      checked={filter.hasAssignedAgentInverted}
                      onCheckedChanged={value => {
                        updateFilter(dispatch, {
                          ...filter,
                          hasAssignedAgentInverted: value,
                        });
                      }}
                    />
                    <FilterAdminSelect
                      label={L.allHomes.assignedAgent}
                      disabled={
                        !filter.hasAssignedAgentEnabled ||
                        filter.hasAssignedAgentInverted
                      }
                      userId={filter.assignedAgentId ?? null}
                      onUserIdChanged={value => {
                        updateFilter(dispatch, {
                          ...filter,
                          assignedAgentId: value,
                        });
                      }}
                    />
                  </Grid>
                ),
              },
              {
                label: L.allHomes.state,
                icon: <HomeIcon />,
                enabled: filter.homeStatesEnabled,
                onEnabledChanged: value => {
                  updateFilter(dispatch, {
                    ...filter,
                    homeStatesEnabled: value,
                  });
                },
                children: (
                  <BoolArrayCheckboxes
                    disabled={!filter.homeStatesEnabled}
                    value={filter.homeStates}
                    onChange={value => {
                      updateFilter(dispatch, {
                        ...filter,
                        homeStates: value,
                      });
                    }}
                    formatLabel={i => {
                      const homeStateValues = Object.values(HomeState);
                      const homeStateValue = homeStateValues[i];
                      return homeStateValue !== undefined
                        ? L.enumerations.HomeState[homeStateValue]
                        : '-';
                    }}
                  />
                ),
              },
              {
                label: L.allHomes.salesProcessStatus,
                icon: <WorkIcon />,
                enabled: filter.salesProcessStatusEnabled,
                onEnabledChanged: value => {
                  updateFilter(dispatch, {
                    ...filter,
                    salesProcessStatusEnabled: value,
                  });
                },
                children: (
                  <BoolArrayCheckboxes
                    disabled={!filter.salesProcessStatusEnabled}
                    value={filter.salesProcessStatus}
                    onChange={value => {
                      updateFilter(dispatch, {
                        ...filter,
                        salesProcessStatus: value,
                      });
                    }}
                    formatLabel={i => {
                      const salesProcessStatusValues =
                        Object.values(SalesProcessStatus);
                      const salesProcessStatusValue =
                        salesProcessStatusValues[i];
                      return salesProcessStatusValue !== undefined
                        ? L.enumerations.SalesProcessStatus[
                            salesProcessStatusValue
                          ]
                        : '-';
                    }}
                  />
                ),
              },
              {
                label: L.allHomes.clear,
                icon: null,
                excluded: true,
                children: (
                  <Box my={1}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleClear}
                    >
                      {L.allHomes.clear}
                    </Button>
                  </Box>
                ),
              },
            ]}
          />
        </Table>
        <DataGrid
          autoHeight
          columns={columns}
          initialState={{
            sorting: { sortModel: sorting ? [sorting] : [] },
          }}
          loading={loading}
          localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
          paginationMode="server"
          paginationModel={paginationModel}
          pageSizeOptions={[PAGE_SIZE]}
          rows={rows}
          rowCount={totalCount}
          sortingMode="server"
          onSortModelChange={model => {
            setSorting(model[0]);
          }}
          onPaginationModelChange={pm => {
            setPaginationModel(pm);
          }}
        />
      </RootCard>
    </>
  );
};

export default Homes;
