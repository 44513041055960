import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import { sv as locale } from '@norban/locale';
import React, { useMemo } from 'react';

import RangeMinMaxInputs from '../components/RangeMinMaxInputs';

export default function useFilterRowNumberOfRooms(
  roomsRangeEnabled: boolean,
  roomsRange: [number | undefined, number | undefined],
  onEnabledChanged: (value: boolean) => void,
  onValueChanged: (
    roomsRange: [number | undefined, number | undefined],
  ) => void,
) {
  const L = locale.backoffice;

  return useMemo(
    () => ({
      label: L.searchFollowers.homeState,
      icon: <MeetingRoomIcon />,
      enabled: roomsRangeEnabled,
      onEnabledChanged,
      children: (
        <RangeMinMaxInputs
          disabled={!roomsRangeEnabled}
          valueMin={roomsRange?.[0]}
          valueMax={roomsRange?.[1]}
          onChange={onValueChanged}
          unit={L.searchFollowers.rooms}
        />
      ),
    }),
    [
      L.searchFollowers.homeState,
      L.searchFollowers.rooms,
      onEnabledChanged,
      onValueChanged,
      roomsRange,
      roomsRangeEnabled,
    ],
  );
}
