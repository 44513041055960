import { ApolloError } from '@apollo/client';
import { Box, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import React from 'react';

type Props = {
  error?: ApolloError;
  data: unknown;
};

const QueryError = ({ error = undefined, data }: Props) => {
  let info;

  console.error(
    'Query Error: ',
    error ?? 'no error details',
    data ?? 'no data details',
  );

  if (error) {
    const graphQLErrors = error.graphQLErrors.map(err => err.message);
    const networkError = error.networkError ? error.networkError.message : null;
    const errorCode = error.message;

    info = (
      <Stack gap={2} mt={2}>
        {graphQLErrors.length > 0 && (
          <Box>
            <Typography variant="h6">GraphQL Errors:</Typography>
            <ul style={{ margin: 0 }}>
              {graphQLErrors.map((msg, i) => (
                <li key={i}>{msg}</li>
              ))}
            </ul>
          </Box>
        )}
        {networkError && (
          <Box>
            <Typography variant="h6">Network Error:</Typography>
            <Typography>{networkError}</Typography>
          </Box>
        )}
        <Box>
          <Typography variant="h6">Error Code:</Typography>
          <Typography>{errorCode}</Typography>
        </Box>
      </Stack>
    );
  }

  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h3" gutterBottom>
          Misslyckades hämta data från servern!
        </Typography>
        {info}
      </Box>
    </Paper>
  );
};

export default QueryError;
