import HomeIcon from '@mui/icons-material/Home';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import { sv as locale } from '@norban/locale';
import React from 'react';
import { useDispatch } from 'react-redux';

import { updateFilter } from '../../actions/followerActions';
import FilterSection from '../../components/FilterSection';
import { FollowerUniqueType, HomeState } from '../../generated/backend/graphql';
import useFilterRowArea from '../../hooks/useFilterRowArea';
import useFilterRowLivingArea from '../../hooks/useFilterRowLivingArea';
import useFilterRowNumberOfRooms from '../../hooks/useFilterRowNumberOfRooms';
import { useGlobalStateWithDelayedCallback } from '../../hooks/useGlobalStateWithDelayedCallback';

export const MAX_LIMIT_FOLLOWERS = 1000;

const SearchForm = ({ filter, rootAreas }) => {
  const L = locale.backoffice;

  const globalDispatch = useDispatch();

  const activeStringToFilter = activeString => {
    switch (activeString) {
      case 'ACTIVE':
        return true;
      case 'INACTIVE':
        return false;
      default:
        return undefined;
    }
  };

  const activeFilterToString = activeFilter => {
    switch (activeFilter) {
      case true:
        return 'ACTIVE';
      case false:
        return 'INACTIVE';
      default:
        return 'ALL';
    }
  };

  // Debounced local filter state to avoid unnecessary re-renders when users are typing
  const [localFilter, setLocalFilter] = useGlobalStateWithDelayedCallback(
    filter,
    globalDispatch,
    updateFilter,
    300,
  );

  const areaRow = useFilterRowArea(
    filter.areasEnabled,
    filter.areaIds,
    rootAreas,
    value =>
      updateFilter(globalDispatch, {
        areasEnabled: value,
      }),
    areas => {
      updateFilter(globalDispatch, { areaIds: areas });
    },
  );
  const numberOfRoomsRow = useFilterRowNumberOfRooms(
    filter.roomsRangeEnabled,
    localFilter.roomsRange,
    value =>
      updateFilter(globalDispatch, {
        roomsRangeEnabled: value,
      }),
    range => setLocalFilter({ roomsRange: range }),
  );
  const livingArea = useFilterRowLivingArea(
    filter.livingAreaRangeEnabled,
    localFilter.livingAreaRange,
    value =>
      updateFilter(globalDispatch, {
        livingAreaRangeEnabled: value,
      }),
    range => setLocalFilter({ livingAreaRange: range }),
  );

  return (
    <Table size="small">
      <FilterSection
        title={L.searchFollowers.typeOfFollowing}
        expanded={filter.followSection}
        onExpandedChanged={value =>
          updateFilter(globalDispatch, {
            followSection: value,
          })
        }
        filters={[
          {
            label: L.searchFollowers.unique,
            icon: <LooksOneIcon />,
            enabled: filter.unique === FollowerUniqueType.Latest,
            onEnabledChanged: value =>
              updateFilter(globalDispatch, {
                unique: !value
                  ? FollowerUniqueType.None
                  : FollowerUniqueType.Latest,
              }),
            children: (
              <Box display="flex" alignItems="center">
                <Typography id="unique-checkbox" gutterBottom>
                  {L.searchFollowers.unique}
                </Typography>
              </Box>
            ),
          },
          {
            label: L.searchFollowers.homeType,
            icon: <WbSunnyIcon />,
            enabled: filter.activeEnabled,
            onEnabledChanged: value =>
              updateFilter(globalDispatch, { activeEnabled: value }),
            children: (
              <Box display="flex" alignItems="center">
                <RadioGroup
                  name="radio-buttons-group"
                  row
                  value={activeFilterToString(filter.active)}
                  onChange={e => {
                    updateFilter(globalDispatch, {
                      active: activeStringToFilter(e.target.value),
                    });
                  }}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label={L.searchFollowers.active}
                    disabled={!filter.activeEnabled}
                    value="ACTIVE"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label={L.searchFollowers.inactive}
                    disabled={!filter.activeEnabled}
                    value="INACTIVE"
                  />
                </RadioGroup>
              </Box>
            ),
          },
        ]}
      />
      <FilterSection
        title={L.searchFollowers.property}
        expanded={filter.homeSection}
        onExpandedChanged={value =>
          updateFilter(globalDispatch, {
            homeSection: value,
          })
        }
        filters={[
          areaRow,
          numberOfRoomsRow,
          livingArea,
          {
            children: (
              <Box display="flex" alignItems="center" py={1}>
                <Typography variant="body" id="limit-textfield" mr={2}>
                  {L.searchFollowers.excludeHomeId}
                </Typography>
                <TextField
                  size="small"
                  value={localFilter.homeId}
                  name="limit"
                  onChange={e => setLocalFilter({ homeId: e.target.value })}
                  type="number"
                />
              </Box>
            ),
          },
        ]}
      />
      <FilterSection
        title={L.searchFollowers.homeState}
        expanded={filter.miscSection}
        onExpandedChanged={value =>
          updateFilter(globalDispatch, {
            miscSection: value,
          })
        }
        filters={[
          {
            label: L.searchFollowers.homeState,
            icon: <HomeIcon />,
            enabled: filter.homeStatesEnabled,
            onEnabledChanged: value =>
              updateFilter(globalDispatch, {
                homeStatesEnabled: value,
              }),
            children: (
              <Box>
                {Object.values(HomeState).map(state => {
                  const checked = filter.homeStates.includes(state);

                  return (
                    <FormControlLabel
                      key={state}
                      label={
                        <Typography style={{ fontSize: 15 }}>
                          {L.enumerations.HomeState[state]}
                        </Typography>
                      }
                      control={
                        <Checkbox
                          checked={checked}
                          disabled={!filter.homeStatesEnabled}
                          color="primary"
                          onChange={() => {
                            const newSearchHomeState = checked
                              ? filter.homeStates.filter(
                                  currentState => currentState !== state,
                                )
                              : [...filter.homeStates, state];
                            updateFilter(globalDispatch, {
                              homeStates: newSearchHomeState,
                            });
                          }}
                        />
                      }
                    />
                  );
                })}
              </Box>
            ),
          },
        ]}
      />
    </Table>
  );
};

export default SearchForm;
